<template>
    <div class="edit-tab-container">
        <div class="edit-tab-inputs-container">
            <div>
                <p class="products-page__datetime-title">Data de Cadastro</p>
                <div class="page-filters__right-select-wrapper">
                <datetime class="form-control" :value="cadastro" ref="datepicker"  type="date"></datetime>
                <span class="material-icons date-icon">
                    today
                </span>
                </div>
            </div>
            <div>
                <p class="products-page__datetime-title">Data primeiro contato</p>
                <div class="page-filters__right-select-wrapper">
                <datetime class="form-control"v-model="contato"  ref="datepicker"  type="datetime"></datetime>
                <span class="material-icons date-icon">
                    today
                </span>
                </div>
            </div>

            <div>
                <p class="products-page__datetime-title">Data prevista primeira cotação</p>
                <div class="page-filters__right-select-wrapper">
                <datetime class="form-control" v-model="prevista" ref="datepicker" auto type="date"></datetime>
                <span class="material-icons date-icon">
                    today
                </span>
                </div>
            </div>
            <div>
                <p class="products-page__datetime-title">Data primeira cotação</p>
                <div class="page-filters__right-select-wrapper">
                <datetime class="form-control" ref="datepicker" v-model="primeira" auto type="datetime"></datetime>
                <span class="material-icons date-icon">
                    today
                </span>
                </div>
            </div>
            <div>
                <p class="products-page__datetime-title">Numero de convites</p>
                <input v-model="client.attendance.numero_convite" type="number" class="edit-tab-input">
            </div>
            <div>
                <p class="products-page__datetime-title">Produtos cadastrados</p>
                <input v-model="client.attendance.produtos_cadastrados" type="number" class="edit-tab-input ">
            </div>
        </div>
        <div class="edit-tab-buttons-container">
            <StandardButton text="Salvar" :action="update_attendance"/>
        </div>

    </div>
</template>

<script>
import StandardButton from '@/components/ui/buttons/StandardButton.vue';
import ClientService from "@/services/v3/client.service";

    export default {
        name: 'EditTabComponent',
        props: ['client','update','close','setLoading'],
        components: {
            StandardButton,
        },
        data() {
            return {
                cadastro: undefined,
                contato: undefined,
                prevista: undefined,
                primeira: undefined,
                svc: new ClientService()
            }
        },
        mounted () {
            this.cadastro = moment(this.client.attendance.data_cadastro).toISOString();
            this.contato = moment(this.client.attendance.data_contato).toISOString();
            this.prevista = moment(this.client.attendance.data_prevista).toISOString();
            this.primeira = moment(this.client.attendance.data_primeira).toISOString();
        },
        methods: {
            update_attendance() {
                this.setLoading(true)
                this.svc.update_attendance(this.client, {
                    id: this.client.attendance.id, 
                    status: this.client.attendance.status.id,
                    attendant_id: this.client.attendance.attendant.id,
                    convite: this.client.attendance.numero_convite,
                    prod_cadastrado: this.client.attendance.produtos_cadastrados,
                    'dt_cadastro': this.cadastro,
                    'dt_contato': this.contato,
                    'dt_prevista': this.prevista,
                    'dt_primeira': this.primeira
                }).then(()=>{
                    this.update()
                    this.close()
                })
            }

        }
    }
</script>

<style lang="scss" scoped>
.edit-tab-container{
    margin: 2em;
}
.products-page__datetime-title {
  font-size: 1.7em;
  font-weight: 400;
  letter-spacing: 0.15px;
  color: #898989;
}
.page-filters__right-select-wrapper {
  display: flex;
  align-items: center;
}
.form-control {
  border-radius: 8px !important;
}
.date-icon {
  background-color: #FF7110;
  color: #fff;
  cursor: pointer;
  padding: 8px 10px;
  border-radius: 0px 8px 8px 0px;
  position: relative;
  left: -6px;
}
.edit-tab-inputs-container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1vh 2vw;
}
.edit-tab-input{
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 8px !important;
}
.edit-tab-input:focus{
    outline: none;
}
.edit-tab-buttons-container{
    display: flex;
    justify-content: flex-end;
    margin-top: 5vh;
}
</style>